import { EFontWeight, EFonts, ESizes } from 'src/config/enums';
import { fluidSizing, toVw } from 'src/utils';
import styled, { css } from 'styled-components';

import { OxLink } from 'src/components/OxLink';
import { createTextStyle } from 'src/utils/createTextStyle';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.basic.lightOatmeal};
        ${fluidSizing([
            { prop: 'padding-right', values: [25, 20, 25] },
            { prop: 'padding-left', values: [25, 20, 25] },
            { prop: 'padding-bottom', values: [25, null] }
        ])}
        ${theme.breakpoints.only('xs')} {
            width: 100%;
        }
        ${theme.breakpoints.up('s')} {
            z-index: 6;

            &.collapsed {
            }
        }

        @media print {
            display: none;
        }
    `
);

export const ContainerInner = styled.div(
    ({ theme }) => css`
        position: relative;
        ${fluidSizing([{ prop: 'padding-top', values: [20, 35, 40] }])}

        ${theme.breakpoints.up('s')} {
            position: sticky;
            ${fluidSizing([
                {
                    prop: 'top',
                    values: [null, ESizes.NavbarHeight, ESizes.NavbarHeight]
                }
            ])}
        }
    `
);

export const MenuWrapper = styled.ul(
    ({ theme }) => css`
        overflow: hidden;
        transition: width 400ms;

        li {
            transition: opacity 400ms;
        }
        ${theme.breakpoints.only('xs')} {
            padding-top: ${toVw(12, 'xs')};
        }

        ${theme.breakpoints.up('s')} {
            ${fluidSizing([{ prop: 'width', values: [null, 120] }])};
        }

        &.collapsed {
            ${theme.breakpoints.only('xs')} {
                height: ${toVw(45, 'xs')};

                li:not(:first-of-type) {
                    opacity: 0;
                }
            }

            ${theme.breakpoints.up('s')} {
                width: 0;
            }
        }
    `
);

export const Link = styled(OxLink)<{ subItem: boolean }>(
    ({ subItem, theme }) => css`
        color: ${theme.colors.primary.main};
        display: flex;
        ${!subItem &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [12, 12, 12], [14, 14, 14])};
        `}
        ${subItem &&
        css`
            ${createTextStyle(EFonts.Hatton, EFontWeight.Normal, [9, 9, 9], [12, 12, 12])};
            ${fluidSizing([{ prop: 'padding-left', values: [8, 8, 8] }])};
        `}

    ${fluidSizing([{ prop: 'margin-bottom', values: [14, 14, 14] }])};
        &.active {
            font-weight: ${EFontWeight.SemiBold};
        }
    `
);

export const ExpanderWrapper = styled.button(
    ({ theme }) => css`
        position: absolute;
        top: ${toVw(48 / 2, 'xs')};
        right: 0;
        min-height: ${toVw(35, 'xs')};
        transform: translateY(-50%);
        color: ${theme.colors.primary.main};
        text-transform: uppercase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.SemiBold, [10, 0, 0], [20, 0, 0])}
        ${theme.breakpoints.up('s')} {
            top: 5px;
            min-height: 0;
            background-color: ${theme.colors.basic.white};
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap;
            font-size: 0;
            padding: 10px;
            transform: none;
            right: -18px;

            &::before {
                content: ' ';
                border: solid ${theme.colors.basic.gold};
                border-width: 2px 0;
                display: block;
                width: 15px;
                height: 20px;
            }

            &:after {
                content: ' ';
                border-top: 2px solid ${theme.colors.basic.gold};
                width: 10px;
                display: block;
                position: absolute;
                top: calc(50% - 1px);
            }
        }

        ${theme.breakpoints.up('l')} {
            top: 10px;
            right: -25px;

            &::before {
                width: 25px;
                height: 20px;
            }

            &:after {
                width: 15px;
            }
        }
    `
);
